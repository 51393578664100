<template>
  <div class="campaign-edit-page">
    <h2>Edit Campaign</h2>

    <campaign-form
      v-if="campaign"
      v-model="campaign"
    />

    <b-button @click="submit">Save</b-button>
  </div>
</template>

<script>
import CampaignForm from '../components/CampaignForm.vue'

export default {
  name: 'CampaignCreate',

  components: {
    CampaignForm
  },

  computed: {
    campaignId () {
      return this.$route.params.id
    }
  },

  data () {
    return {
      campaign: null
    }
  },

  mounted () {
    this.fetch()
  },

  methods: {
    async fetch () {
      const resp = await this.$campaigns.get(this.campaignId)
      this.campaign = resp.data.data
    },

    validate () {
      let valid = true

      return valid
    },

    async submit () {
      if (!this.validate()) {
        console.warn('Invalid form')
        return
      }

      try {
        await this.$campaigns.update(this.campaignId, this.campaign)
      }
      catch (e) {
        console.error(e.message)
      }
    }
  }
}
</script>

<style lang="scss">
</style>
